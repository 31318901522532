import dynamic, { LoaderComponent } from 'next/dynamic'
import { FC } from 'react'
import { Section } from '~/components/common/section'
import { Container } from '~/components/layout/container'
import { ButtonLink } from '~/components/primitives/cta'
import { SITEMAP } from '~/lib/sitemap'

import s from './hero.module.scss'

const Sky = dynamic(
  () => import('~/components/common/sky').then((m) => m.Sky) as LoaderComponent,
  {
    ssr: false
  }
)

export const Hero: FC = () => {
  return (
    <Section className={s.section}>
      <div className={s['bg-container']}>
        <div className={s['child']}>
          <Sky />
        </div>
      </div>
      <Container className={s.container}>
        <h1 className={s.title}>page not found</h1>
        <ButtonLink
          size="big"
          href={SITEMAP.home.href || '/'}
          aria-label={SITEMAP.home.label}
        >
          Drive Home
        </ButtonLink>
      </Container>
    </Section>
  )
}
