import type { ReactNode } from 'react'

export type SidebarType = {
  id: number
  title: string
  links: {
    id: number
    href: string
    label: string
  }[]
}

export type MainContentType = {
  id: number
  title: string
  subtitle: string
  icon: ReactNode
  links: {
    id: number
    href: string
    label: string
    icon: ReactNode
  }[]
}

export type SitemapType = {
  href?: string
  label: string
  dropdown?: {
    sidebar: SidebarType[]
    mainContent: MainContentType[]
  }
}

type SitemapKeys = 'home' | 'roadmap' | 'aboutUs' | 'docs' | 'discord' | 'blog'

export const SITEMAP: Record<SitemapKeys, SitemapType> = {
  home: {
    href: '/',
    label: 'Home'
  },
  roadmap: {
    href: '/roadmap',
    label: 'Roadmap'
  },
  aboutUs: {
    href: '/about',
    label: 'About'
  },
  docs: {
    href: 'https://docs.ridethebus.xyz/',
    label: 'Docs'
  },
  discord: {
    href: 'https://discord.gg/uJYZFqFuMZ',
    label: 'Discord'
  },
  blog: {
    href: 'https://blog.ridethebus.xyz/',
    label: 'Blog'
  }
}
